<template>
    <div style="min-height: 200px; display: flex; align-items: center; justify-content: center" v-loading="loading">
        <template v-if="attempt.url === null">
            <el-button name="kyc-start" @click="createAttempt(() => pollAttemptStatus())" type="primary" size="large">
                <strong>Zahájit ověřování</strong>
            </el-button>
        </template>
        <template v-else>
            <iframe
                :src="attempt.url"
                frameborder="0"
                allow="camera;microphone"
                style="width: 100%; min-height: 900px">
            </iframe>
        </template>
    </div>
</template>

<script>
export default {
    emits: ['completed'],
    props: {
        existingKycUuid: {
            type: String,
            required: false,
            default: null
        },
        documentExternalUuid: {
            type: String,
            required: false,
            default: null
        }
    },
    data(){
        return {
            kycUuid: this.existingKycUuid,
            attempt: {
                uuid: null,
                url: null
            },
            loading: false
        }
    },
    methods: {
        async createAttempt(callback){
            this.loading = true
            /*************** Move this somewhere else, maybe a wrapper component, because it is only needed when used externally. **********/
            if (this.kycUuid === null){
                this.kycUuid = (await this.$api.post('/external-api/' + this.$context.client.slug + '/kyc/store', {
                    document_external_uuid: this.documentExternalUuid,
                })).data.uuid
            }
            /***************************************************************************************************************************** */


            const { data: attempt } = await this.$api.post('/external-api/kyc-attempts/store/' + this.kycUuid)
            this.attempt.uuid = attempt.uuid
            this.attempt.url = attempt.url
            this.loading = false

            callback()
        },

        async pollAttemptStatus(){
            let interval = setInterval(async () => {
                const { data } = await this.$api.get('/external-api/kyc-attempts/' + this.attempt.uuid + '/result')

                if (data.status !== 'CREATED') {
                    clearInterval(interval)
                    this.$emit('completed', data.status)
                    this.$emit('finished', data)
                }
            }, 3000);
        },
    }
}
</script>
