<!-- <ls-docx-viewer :doc="doc"></ls-docx-viewer> uses default file -->
<!-- <ls-docx-viewer :doc="doc" :file="Výpověď.docx"></ls-docx-viewer> uses "file" -->
<!-- <ls-docx-viewer :doc="doc" url="https://calibre-ebook.com/downloads/demos/demo.docx"></ls-docx-viewer> load external URL -->
<template>
  <div v-loading="linkLoading" :element-loading-text="$t('viewer.loading')">

    <div v-if="link" :style="'position:relative; '+'width: 100%; height: '+height+'; min-height: initial'" >
        <!--
        <div style='background-color:White;top:0px; height: 47px; position: absolute; left:0px; width: 100%;'></div>
        <div style='background-color:LightGray;top:47px; height: 5px; position: absolute; left:0px; width: 100%;'></div>
        -->
        <iframe id="office" :src="'https://docs.google.com/gview?url='+link+'&embedded=true'" width='100%' height='100%' frameborder='0' style='border: none;'></iframe>
    </div>

    <div v-else-if="error">
	  <el-alert
	    :title="$t('viewer.error')"
	    :description="$t('viewer.errorDesc')"
	    type="error"
	    :closable="false"
	    show-icon>
	  </el-alert>
  	</div>
    <div v-else :style="'width: 100%; height: '+height+'; min-height: initial'"></div>
  </div>
</template>

<script>
  export default {
    inject: {
      template: {
        default: null
      }
    },
    props: {
      doc: Object,
      file: {
      	type: String,
      	default: null
      },
      url: {
      	type: String | Promise,
      	default: null
      },
      height: {
        type: String,
        default: "50vh"
      },
      keep: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "pdf"
      },
      attachments: {
        type: Array,
        default: null
      }
    },
    data () {
      return {
      	link: null,
      	linkLoading: true,
      	error: false,
      }
    },
    mounted: async function() {
      if(this.url !== null) {//just open external docx - no "doc" data required
        this.link = await this.url;
        this.linkLoading = false;
      }
      else {//generate docx, temporarily save it and preview it
        if(this.doc) {
          var fn = this.getDownloadPdfUrl;
          if(this.type == "docx")
            fn = this.getDownloadUrl;

          fn(this.file, this.keep, undefined, this.attachments).then(async (value) => {
            var out = await value;
            this.link = out.replace(/(^\w+:|^)\/\//, '');
            this.linkLoading = false;
          }).catch(res => {
            this.error = true;
            this.linkLoading = false;
            console.log(res);
            this.$sentry.captureException(res);
          });
        }
        else {//no "doc" data specified!
          this.error = true;
          this.linkLoading = false;
          throw new Error('Specify :doc="doc" or url for ls-viewer component!');
        }
      }
    },
    methods: {


    }
  }
</script>

<i18n>
  {
    "cz": {
      "viewer": {
        "loading": "Připravuji náhled dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhled dokumentu se nepodařilo zobrazit."
      }
    },
    "sk": {
      "viewer": {
        "loading": "Pripravujem náhľad dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhľad dokumentu sa nepodarilo zobraziť."
      }
    },
    "en": {
      "viewer": {
        "loading": "Preparing document preview ...",
        "error": "Error!",
        "errorDesc": "The document preview could not be displayed."
      } 
    },
    "pl": {
      "viewer": {
        "loading": "Przygotowanie podglądu dokumentu ...",
        "error": "Błąd!",
        "errorDesc": "Nie można wyświetlić podglądu dokumentu."
      } 
    },
    "hu": {
      "viewer": {
        "loading": "A dokumentum előnézete ...",
        "error": "Hiba!",
        "errorDesc": "A dokumentum előnézete nem jeleníthető meg."
      } 
    }
  } 
</i18n>
