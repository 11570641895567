<template>
  <el-select
    class="full-width"
    v-model="selectedValue"
    @change="handleChange(JSON.parse(selectedValue))"
    :placeholder="placeholder"
    filterable
    :disabled="city==null">
      <el-option
        v-for="(item, key) in CityKUs"
        :key="key"
        :label="item.nazev"
        :value="JSON.stringify(item)">
      </el-option>       
    </el-select>
</template>

<script>
  export default {
    props: [
      'placeholder',
      'city',
      'value'
    ],
    data() {
      return {
        KUs: [],
        selectedValue: null
      };
    },
    methods: {
      handleChange(item) {
        this.$emit('input', item);
      },
    },
    computed: {
      CityKUs: function () {
        if(this.city)
          return this.KUs.filter(KU => KU.kodObce === this.city.kodObce);
        return [];
      }
    },
    watch: {
      city: function (newC, oldC) {
        if(newC !== oldC) {
          this.selectedValue = null;
          this.handleChange(this.selectedValue);
        }
      },
      CityKUs: function (KUs) {
        if(KUs.length == 1) {
          this.selectedValue = JSON.stringify(KUs[0]);
          this.handleChange(JSON.parse(this.selectedValue));
        }
      },
      value: function (newValue) {
        this.selectedValue = JSON.stringify(newValue);
      }
    },
    created() {
      if(this.value) {
        this.KUs.push(this.value);
        this.selectedValue = JSON.stringify(this.value);
      }
      this.$api.get('/external-api/KU')
          .then(res => {
            this.KUs = res.data;
          })
          .catch(res => {
            this.KUs = [];
          });
    }
  };
</script>