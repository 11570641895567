<template>
  <ls-card :title="$t('preview.title')">
    <div v-loading="linkLoading" :element-loading-text="$t('preview.loading')">
      <div v-if="link" :style="'position:relative; '+'width: 100%; height: 70vh; min-height: initial'" >
        <embed :src="link" type="application/pdf" width="100%" height="100%" />
      </div>

      <div v-else-if="error">
        <el-alert
          :title="$t('preview.error')"
          :description="$t('preview.errorDesc')"
          type="error"
          :closable="false"
          show-icon>
        </el-alert>
      </div>
      <div v-else :style="'width: 100%; height: 70vh; min-height: initial'"></div>
    </div>
  </ls-card>
</template>

<script>
  export default {
    inject: ['doc', 'template'],
    props: {
      input: {
        type: String,
        default: function() {
          return this.template.defaultFile;
        }
      },
      tmpl: {
        type: String,
        default: null
      }
    },
    data() {
      return {
      	pdf: true,
        link: null,
      	linkLoading: true,
      	error: false,
        canceled: false
      }
    },
    computed: {
      postFile() {
          return {
            inputFile: this.input,
            inputTemplate: this.tmpl
          }
      }
    },
    mounted: async function() {
      if(!this.postFile.inputFile) throw "Missing input file";

      try {
        var file = await this.generate(this.postFile);
      }
      catch(e) {
        this.error = true;
        return;
      }
      finally {
        this.linkLoading = false;
      }

      this.link = this.hostname + `/documents/${this.doc.id}/generate/${file}/preview/preview.pdf`;
  	},
    
    destroyed() {
      this.canceled = true;
    },

    methods: {
      async generate(input) {
        var file = await this.$api.post(`proculus-api/documents/${this.doc.id}/generate/prepare`, {
          format: this.pdf ? 'pdf' : 'original',
          inputFile: input.inputFile,
          inputTemplate: input.inputTemplate,
          ...(this.$context.role.superuser ? {
            templateVersionId: this.template.versionId,
            mixinVersionId: this.template.mixinVersionId,
            concept: this.template.concept,
          } : {})
        }).then(x=>x.data);

        while(1) {
          let ready = await this.$api.get(`proculus-api/documents/${this.doc.id}/generate/${file}/isReady`).then(x=>x.data);
          if(ready || this.canceled) break;
          await this.sleep(3000);
        }

        return file;
      },



    }
  }
</script>

<i18n>
  {
    "cz": {
      "preview": {
        "title": "Náhled",
        "loading": "Připravuji náhled dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhled dokumentu se nepodařilo zobrazit."
      }
    },
    "sk": {
      "preview": {
        "title": "Náhľad",
        "loading": "Pripravujem náhľad dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhľad dokumentu sa nepodarilo zobraziť."
      }
    },
    "en": {
      "preview": {
        "title": "Preview",
        "loading": "Preparing document preview ...",
        "error": "Error!",
        "errorDesc": "The document preview could not be displayed."
      } 
    },
    "pl": {
      "preview": {
        "title": "Zapowiedź",
        "loading": "Przygotowanie podglądu dokumentu ...",
        "error": "Błąd!",
        "errorDesc": "Nie można wyświetlić podglądu dokumentu."
      } 
    },
    "hu": {
      "preview": {
        "title": "Előnézet",
        "loading": "A dokumentum előnézete ...",
        "error": "Hiba!",
        "errorDesc": "A dokumentum előnézete nem jeleníthető meg."
      } 
    }
  } 
</i18n>
