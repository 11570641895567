<template>
  <div style="text-align: center;" v-loading="loading">
    <template v-if="!pay.show">
      <!-- if desktop, device selector -->
      <template v-if="!isMobile.any && !browser && !external.switch">
        <el-alert type="warning" :closable="false" show-icon>
          <div slot="title" style="word-break: break-word; text-align: justify;">
            {{ $t('ls.kyc.intro') }}
          </div>
        </el-alert>
        <br>
        <div style="margin-bottom: 10px">
          <el-button plain @click="startPhone">{{ $t('ls.kyc.device.phone') }}</el-button>
        </div>
        <div>{{ $t('ls.kyc.device.or') }}</div>
        <div>
          <el-button type="text" @click="startBrowser">{{ $t('ls.kyc.device.desktop') }}</el-button>
        </div>
      </template>

      <!-- if phone from browser -->
      <div v-else-if="external.switch" style="text-align: left;" v-loading="result && result.phone" :element-loading-text="$t('ls.kyc.phone.loading')">
        <el-alert
          type="warning"
          :closable="false"
          show-icon>
          <div slot="title" style="word-break: break-word; text-align: justify;">
            {{ $t('ls.kyc.phone.intro') }}
          </div>
        </el-alert>
        <br>

        <el-row :gutter="20">
          <el-col :md="12">
            <el-form-item :label="$t('ls.kyc.phone.qr')">
              <div v-loading="external.qr == null" style="width: 200px; height: 200px; clear: both"><img :src="external.qr" width="100%"></div>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form hide-required-asterisk :model="external.data" ref="sms">
              <el-form-item :label="$t('ls.kyc.phone.sms')" prop="phone" :rules="[rl.phone, rl.required]">
                <el-input :placeholder="$t('ls.kyc.phone.phoneNumber')" v-model="external.data.phone">
                  <el-button :disabled="external.smsLoading || external.smsDone" slot="append" @click="sms()">
                    <i v-if="external.smsLoading" class="fa-fw fas fa-spinner fa-spin"></i>
                    <i v-else-if="external.smsDone" class="fa-fw fas fa-check"></i>
                    <i v-else class="fa-fw far fa-paper-plane"></i>
                    {{ $t('ls.kyc.phone.send') }}
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>

        <label class="el-form-item__label">{{ $t('ls.kyc.phone.link') }}</label>
        <ls-copy v-model="external.url"></ls-copy>

        <div style="font-style: italic; line-height: 40px; color: #606266">{{ $t('ls.kyc.phone.limit') }}</div>
      </div>

      <!-- if phone directly or browser -->
      <template v-else>
        <el-steps :active="active" finish-status="success" align-center v-if="steps">
          <el-step :title="$t('ls.kyc.step.type')"></el-step>
          <template v-if="!secondid">
            <el-step :title="$t('ls.kyc.step.upload')"></el-step>
          </template>
          <template v-else>
            <el-step :title="$t('ls.kyc.step.upload1')"></el-step>
            <el-step :title="$t('ls.kyc.step.upload2')"></el-step>
          </template>
          <el-step :title="$t('ls.kyc.step.selfie')"></el-step>
        </el-steps>

        <!-- 1. select document type -->
        <template v-if="active == 0">
          <div class="h4">
            <template v-if="!secondid">{{ $t('ls.kyc.process.step1.select1') }}</template>
            <template v-else>{{ $t('ls.kyc.process.step1.select2') }}</template>
          </div>

          <el-button class="seller-select-button" v-bind:class="{'active-btn':type == 'IdentityCard' || type2 == 'IdentityCard'}" @click="typeSelect('IdentityCard')">
            <span class="h4">{{ $t('ls.kyc.process.step1.id') }}</span>
            <div class="clearfix bottom">
              <span class="address-text">{{ $t('ls.kyc.process.step1.bothSides') }}</span>
            </div>
          </el-button>

          <!--<el-button v-if="driversLicence" class="seller-select-button" v-bind:class="{'active-btn':type == 'DriversLicense' || type2 == 'DriversLicense'}" @click="typeSelect('DriversLicense')">
            <span class="h4">{{ $t('ls.kyc.process.step1.drivingLicence') }}</span>
            <div class="clearfix bottom">
              <span class="address-text">{{ $t('ls.kyc.process.step1.frontSide') }}</span>
            </div>
          </el-button>-->

          <el-button class="seller-select-button" v-bind:class="{'active-btn':type == 'Passport' || type2 == 'Passport'}" @click="typeSelect('Passport')">
            <span class="h4">{{ $t('ls.kyc.process.step1.passport') }}</span>
            <div class="clearfix bottom">
              <span class="address-text">{{ $t('ls.kyc.process.step1.pictureSide') }}</span>
            </div>
          </el-button>
          <br>
          <el-button :disabled="secondid ? !type2 : !type" @click="step1Next">{{ $t('ls.kyc.process.continue') }}</el-button>
        </template>

        <!-- 2. upload document -->
        <template v-if="active == 1">
          <el-alert
            type="info"
            style="max-width: 500px; margin: 0 auto;"
            :closable="false">
            <template slot="title">
              <div><strong>{{$t('ls.kyc.process.instructions[0].title')}}</strong></div>
              <ul><li v-for="(step, si) in $t('ls.kyc.process.instructions[0].steps')" :key="'s.0.'+si">{{step}}</li></ul>
            </template>
          </el-alert>
          <br>
          <el-alert
            type="error"
            style="max-width: 500px; margin: 0 auto;"
            :closable="false">
            <template slot="title">
              <div><strong>{{$t('ls.kyc.process.instructions[1].title')}}</strong></div>
              <ul><li v-for="(step, si) in $t('ls.kyc.process.instructions[1].steps')" :key="'s.1.'+si">{{step}}</li></ul>
            </template>
          </el-alert>
          <br>

          <div class="h4">
            {{ $t('ls.kyc.process.step2.picture') }}
            <span v-if="type == 'IdentityCard'">{{ $t('ls.kyc.process.step2.ofId') }}</span>
            <span v-else-if="type == 'DriversLicense'">{{ $t('ls.kyc.process.step2.ofDrivingLicence') }}</span>
            <span v-else-if="type == 'Passport'">{{ $t('ls.kyc.process.step2.ofPassport') }}</span>
          </div>

          <div class="upload-wrapper">
            <el-form-item :label="type == 'Passport' ? $t('ls.kyc.process.step1.pictureSide') : $t('ls.kyc.process.step1.frontSide')">
              <el-upload
                accept="image/jpeg"
                class="avatar-uploader"
                :show-file-list="false"
                action=""
                :http-request="handleHttp(passport.p1)"
                :on-error="handleError"
                :before-upload="beforeUpload">
                <img v-if="passport.p1.url" :src="passport.p1.url" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item :label="$t('ls.kyc.process.step1.backSide')" v-if="type == 'IdentityCard'">
              <el-upload
                accept="image/jpeg"
                class="avatar-uploader"
                :show-file-list="false"
                action=""
                :http-request="handleHttp(passport.p2)"
                :on-error="handleError"
                :before-upload="beforeUpload">
                <img v-if="passport.p2.url" :src="passport.p2.url" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>

          <el-button :disabled="!passportCheck" @click="active++">{{ $t('ls.kyc.process.continue') }}</el-button>
        </template>

        <template v-if="secondid && active == 2">
          <el-alert
            type="info"
            style="max-width: 500px; margin: 0 auto;"
            :closable="false">
            <template slot="title">
              <div><strong>{{$t('ls.kyc.process.instructions[0].title')}}</strong></div>
              <ul><li v-for="(step, si) in $t('ls.kyc.process.instructions[0].steps')" :key="'s.0.'+si">{{step}}</li></ul>
            </template>
          </el-alert>
          <br>
          <el-alert
            type="error"
            style="max-width: 500px; margin: 0 auto;"
            :closable="false">
            <template slot="title">
              <div><strong>{{$t('ls.kyc.process.instructions[1].title')}}</strong></div>
              <ul><li v-for="(step, si) in $t('ls.kyc.process.instructions[1].steps')" :key="'s.1.'+si">{{step}}</li></ul>
            </template>
          </el-alert>
          <br>

          <div class="h4">
            {{ $t('ls.kyc.process.step2.picture') }}
            <span v-if="type2 == 'IdentityCard'">{{ $t('ls.kyc.process.step2.ofId') }}</span>
            <span v-else-if="type2 == 'DriversLicense'">{{ $t('ls.kyc.process.step2.ofDrivingLicence') }}</span>
            <span v-else-if="type2 == 'Passport'">{{ $t('ls.kyc.process.step2.ofPassport') }}</span>
          </div>

          <div class="upload-wrapper">
            <el-form-item :label="type2 == 'Passport' ? $t('ls.kyc.process.step1.pictureSide') : $t('ls.kyc.process.step1.frontSide')">
              <el-upload
                accept="image/jpeg"
                class="avatar-uploader"
                :show-file-list="false"
                action=""
                :http-request="handleHttp(passport2.p1)"
                :on-error="handleError"
                :before-upload="beforeUpload">
                <img v-if="passport2.p1.url" :src="passport2.p1.url" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item :label="$t('ls.kyc.process.step1.backSide')" v-if="type2 == 'IdentityCard'">
              <el-upload
                accept="image/jpeg"
                class="avatar-uploader"
                :show-file-list="false"
                action=""
                :http-request="handleHttp(passport2.p2)"
                :on-error="handleError"
                :before-upload="beforeUpload">
                <img v-if="passport2.p2.url" :src="passport2.p2.url" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>

          <el-button :disabled="!passportCheck2" @click="active++">{{ $t('ls.kyc.process.continue') }}</el-button>
        </template>

        <!-- 3. upload selfie -->
        <template v-if="secondid ? active == 3 : active == 2">
          <el-alert
            type="success"
            style="max-width: 500px; margin: 0 auto;"
            :closable="false">
            <template slot="title">
              <div><strong>{{$t('ls.kyc.process.instructions[2].title')}}</strong></div>
              <ul><li v-for="(step, si) in $t('ls.kyc.process.instructions[2].steps')" :key="'s.0.'+si">{{step}}</li></ul>
            </template>
          </el-alert>
          <br>

          <el-form-item :label="$t('ls.kyc.process.step3.selfie')">
            <el-upload
              accept="image/jpeg"
              class="avatar-uploader"
              :show-file-list="false"
              action=""
              :http-request="handleHttp(selfie)"
              :on-error="handleError"
              :before-upload="beforeUpload">
              <img v-if="selfie.url" :src="selfie.url" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-button :disabled="!selfieCheck" @click="upload">{{ $t('ls.kyc.process.continue') }}</el-button>
        </template>

        <!-- 4. analyze / extract -->
        <template v-if="secondid ? active == 4 : active == 3">
          <div v-if="error">
            <el-alert type="error" :closable="false" show-icon>
              <div slot="title" style="word-break: break-word; text-align: justify;">
                {{ $t('ls.kyc.error.upload') }}
              </div>
            </el-alert>
          </div>
          <div v-else>
            <div>1/2 {{ $t('ls.kyc.process.step4.uploading') }}</div>
            <el-progress status="success" style="max-width: 500px; margin: 0 auto;" :percentage="uploadPercentage"></el-progress>

            <template v-if="uploadPercentage == 100">
              <div style="margin-top: 20px;">2/2 {{ $t('ls.kyc.process.step4.analysing') }}</div>
              <div style="font-size: 1.5em; margin-bottom: 20px;"><i class="el-icon-loading"></i></div>
            </template>
          </div>
        </template>

        <!-- return result -->
      </template>
    </template>

    <template v-else>
      <div>
        <el-alert type="warning" :closable="false" show-icon>
          <div slot="title" style="word-break: break-word; text-align: justify;">
            {{ $t('ls.kyc.payment.intro') }}
          </div>
        </el-alert>
        <br>
        <strong>{{ $t('ls.kyc.payment.title') }}</strong><br>
        {{ $t('ls.kyc.payment.cu') }}: {{ micropayment.cu }}<br>
        {{ $t('ls.kyc.payment.vs') }}: {{ micropayment.vs }}<br>
        {{ $t('ls.kyc.payment.msg') }}: {{ micropayment.comment }}<br>
        {{ $t('ls.kyc.payment.amount') }}: 1 Kč<br>
        <br>
        {{ $t('ls.kyc.payment.qr') }}:<br>
        <img :src="pay.qr" v-loading="!pay.qr" width="150"><br>
        <br>
        <el-button @click="finish">{{ $t('ls.kyc.payment.finish') }}</el-button>
      </div>
    </template>


  </div>
</template>

<style scoped lang="scss">
  .seller-select-button {
    text-align: left;
    width: 100%;
    margin: 0 0 20px;
  }

  .seller-select-button:focus {
    color: #606266;
    border-color: #DCDFE6;
    background-color: #FFFFFF;
  }

  .seller-select-button.active-btn, .seller-select-button.active-btn:focus {
    color: #1aa1bb;
    border-color: #b3e0e8;
    background-color: #e6f5f7;
  }

  .seller-select-button:hover {
    color: #1aa1bb;
    border-color: #1aa1bb;
    background-color: inherit;
  }

  .seller-select-button.active-btn:hover {
    border-color: #1aa1bb;
    background-color: #e6f5f7;
  }

  .address-text {
    font-size: small;
    color: #999;
  }

  .upload-wrapper::v-deep {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .el-form-item {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .avatar-uploader::v-deep {
      .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .el-upload:hover {
      border-color: #409EFF;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    display: block;
  }
</style>

<script>
  import axiosRetry from 'axios-retry';

  export default {
    props: {
      kycCheckUuid: {
        type: String,
        default: null
      },
      steps: {
        type: Boolean,
        default: true
      },
      phone: {
        type: Boolean,
        default: null
      },
      uuid: {
        type: String,
        default: null
      },
      micropayment: {
        type: Object,
        required: false
      },
      secondid: {
        type: Boolean,
        default: false
      },
      driversLicence: {
        type: Boolean,
        default: true
      },
      isRepairable: {
        type: Boolean,
        required: false,
        default: false,
      },
      // In case of external ls-kyc with no URL prop
      documentExternalUuid: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        active: 0,
        error: false,
        id: null,
        browser: false,
        type: null,
        type2: null, //for second id
        passport: {
          p1: {
            file: null,
            url: null
          },
          p2: {
            file: null,
            url: null
          },
        },
        passport2: {
          p1: {
            file: null,
            url: null
          },
          p2: {
            file: null,
            url: null
          },
        },
        selfie: {
          file: null,
          url: null
        },
        uploadPercentage: 0,
        loading: false,
        cancelFetch: false,
        result: null,

        external: {
          switch: false,
          qr: null,
          url: null,
          data: {
            phone: null,
          },
          smsLoading: false,
          smsDone: false
        },
        pay: {
          show: false,
          qr: null
        }

      };
    },

    mounted() {
      axiosRetry(this.$api, { retries: 3 });
    },
    created: async function() {
      if(this.uuid)
        this.id = this.uuid;

      //opening on phone trough QR/SMS
      if(this.phone)
        this.browser = true;

      //opening directly on phone
      if(this.isMobile.any) {
        this.browser = true;
        this.startSession();
      }

      //generate QR for micropayment
      if(this.micropayment && this.micropayment.enabled)
        this.generateQr();
    },

    computed: {
      passportCheck() {
        if(this.type == 'IdentityCard')
          return this.passport.p1.file != null && this.passport.p2.file != null;
        else if(this.type == 'DriversLicense' || this.type == 'Passport')
          return this.passport.p1.file != null;
      },
      passportCheck2() {
        if(this.type2 == 'IdentityCard')
          return this.passport2.p1.file != null && this.passport2.p2.file != null;
        else if(this.type2 == 'DriversLicense' || this.type2 == 'Passport')
          return this.passport2.p1.file != null;
      },
      selfieCheck() {
        return this.selfie.file != null;
      },
    },

    methods: {
      async startSession() {
        try {
          let kycCheckuuid = this.kycCheckUuid;

          // Create kycCheck if there isnt one (from prop)
          if (!kycCheckuuid){
            kycCheckuuid = (await this.$api.post('/external-api/'+ this.$context.client.slug + '/kyc/store', {
              type: "Classic",
              document_external_uuid: this.documentExternalUuid,
              additional_document_required: false,
              provider: 'zignsec',
            })).data.uuid;
          }

          // Create kyc within existing kycCheck if there is no existing kyc id (uuid from prop)
          if (!this.id){
            this.id = (await this.$api.post('/external-api/kyc-attempts/store/' + kycCheckuuid)).data.uuid;
          }
        }
        catch(e) {
          this.$catch(e, this.$t('ls.kyc.error.connection'));
        };
      },

      step1Next() {
        if(this.secondid && this.type == 'DriversLicense') { //switch
          this.type = this.type2;
          this.type2 = 'DriversLicense';
        }

        this.active++;
      },

      typeSelect(type) {
        if(this.type == null)
          this.type = type;
        else if(this.type == type) {
          this.type = null;
          if(this.secondid && this.type2 != null) {
            this.type = this.type2;
            this.type2 = null;
          }
        }
        else if(this.secondid && this.type2 == null)
          this.type2 = type;
        else if(this.secondid && this.type2 == type)
          this.type2 = null;
        else
          this.type = type;
      },

      handleHttp(file) {
        return (options) => {
          file.file = options.file;
          const FR = new FileReader();
          FR.readAsDataURL(options.file);
          FR.onload = () => {
            file.url = FR.result;
          };
          FR.onerror = this.handleError;
        }
      },

      upload: async function() {
        this.active++;

        await this.uploadKyc();
      },

      async uploadKyc() {
        try {
          var bodyFormData = new FormData();

          bodyFormData.append('type', this.type);
          bodyFormData.append('selfie', this.selfie.file);
          bodyFormData.append('file', this.passport.p1.file);

          if(this.type == 'IdentityCard')
            bodyFormData.append('file2', this.passport.p2.file);

          /* second ID */
          if(this.secondid) {
            bodyFormData.append('secondid', this.secondid);
            bodyFormData.append('type2', this.type2);
            bodyFormData.append('file3', this.passport2.p1.file);
            if(this.type2 == 'IdentityCard')
              bodyFormData.append('file4', this.passport2.p2.file);
          }

          await this.$api({
            method: "post",
            url: "/external-api/kyc-legacy/upload/" + this.id,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }
          });

          if (this.isRepairable){
            this.$emit('completed');
          }
          else{
            this.fetch(this.getKyc);
          }
        }
        catch(err) {
          this.handleError(err, bodyFormData);

          this.errorStop();
        }
      },

      async getKyc() {
        try {
          var res = await this.$api.get("/external-api/kyc-attempts/"+this.id+"/result/");

          this.result = res.data;
          const status = res.data.status;
          if(status == "FRAUD FAILED" || status == "KYC FAILED" || status == "ACCEPTED" || status == "FAILED" || status == "DECLINED") {
            if(status == "ACCEPTED" && this.micropayment && this.micropayment.enabled)
              this.pay.show = true;
            else
              this.finish();

            return;
          }

          if(status == "CREATED" || status == "FRAUD FINISHED" || status == "KYC STARTED") {
            this.fetch(this.getKyc);
            return;
          }

          //unexpected status
          throw res.data;
        }
        catch(e) {
          this.$catch(e, this.$t('ls.kyc.error.analysing'));
          this.errorStop();
        };
      },

      fetch(fn, id = undefined, promise = undefined) {
        if(!this.cancelFetch)
          setTimeout(async () => fn(id, promise), 6000);
      },

      cancel() {
        this.cancelFetch = true;
      },

      finish() {
        this.$emit('finished', this.result);
      },

      handleError(err, file=null, fileList=null) {
        console.log(err);
        console.log(file);
        this.$catch(err, this.$t('ls.kyc.error.upload'));
      },

      errorStop() {
        this.error = true;
      },

      beforeUpload(file) {
        const maxSize = 10; //MB
        const isSmall = (file.size / 1024 / 1024) < maxSize;

        if(!isSmall)
          this.$message.error(this.$t('ls.kyc.error.filesize')+' '+maxSize+'MB!');

        return isSmall;
      },

      async startBrowser() {
        await this.startSession();

        this.browser = true;
      },

      async startPhone() {
        await this.startSession();

        this.external.switch = true;

        //generate QR code
        this.loading = true;
        this.external.url = this.hostname + '/public/kyc-legacy/phone/' + (this.id) + '/' + this.$i18n.locale;

        this.loading = false;

        try {
          var res = await this.$api.post('/external-api/qr-code', {
            data: this.external.url
          });

          this.external.qr = res.data;
        }
        catch(e) {
          this.$catch(e, this.$t('ls.kyc.error.qr'));
        };

        this.fetch(this.getKyc);
      },

      sms() {
        this.$refs['sms'].validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: this.$t('ls.kyc.error.validate'),
              type: 'error'
            });
            return false;
          }

          this.external.smsLoading = true;
          this.$api.post('/external-api/kyc-legacy/sms', {
            url: this.external.url,
            phone: this.external.data.phone
            }).then(res => {
              this.external.smsLoading = false;
              this.external.smsDone = true;
            }).catch(e => {
              this.external.smsLoading = false;
              this.$catch(e, this.$t('ls.kyc.error.sms'));
            });
        });
      },

      generateQr() {
        this.$api.post("/external-api/qr-payment/", {
          bankAccount: this.micropayment.cu,
          amount: "1",
          variableSymbol: this.micropayment.vs,
          comment: this.micropayment.comment,
        }).then(res => {
          this.pay.qr = res.data;
        }).catch(e => {
          this.$catch(e, this.$t('ls.kyc.error.qr'));
        });
      },


    }
  };
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "kyc": {
          "step": {
            "type": "Typ dokladu",
            "upload": "Nahrání dokladu",
            "upload1": "První doklad",
            "upload2": "Druhý doklad",
            "selfie": "Selfie"
          },
          "intro": "Ověření totožnosti se skládá z nahrání fotografie dokladů a fotografie obličeje (selfie)",
          "device": {
            "phone": "Odeslat do mobilu",
            "or": "anebo",
            "desktop": "pokračovat na tomto zařízení"
          },
          "phone": {
            "intro": "Ověření můžete provést na svém mobilním telefonu s fotoaparátem. Stránku s ověřením si zobrazíte načtením QR kódu anebo si jej můžete poslat přes SMS.",
            "qr": "Naskenujte QR kód:",
            "sms": "Odeslat přes SMS:",
            "link": "Anebo otevřete odkaz v prohlížeči na Vašem zařízení:",
            "limit": "Platnost odkazu je 10 minut.",
            "phoneNumber": "Telefonní číslo",
            "send": "Odeslat",
            "loading": "Pokračujte na mobilním telefonu..."
          },
          "process": {
            "continue": "Pokračovat",
            "instructions": [{
                "title": "Korektní focení dokladů:",
                "steps": [
                  "Umístěte doklad na desku stolu nebo jinou podložku",
                  "Ujistěte se, že je v místnosti dostatek světla",
                  "Doklad foťte ideálně ze shora"
                ]
              },{
                "title": "Na fotce by neměly být:",
                "steps": [
                  "Odlesky nebo stíny",
                  "Nečitelné nebo zakryté údaje",
                  "Snímek nesmí být rozmazaný"
                ]
              },{
                "title": "Při focení selfie dbejte na to aby:",
                "steps": [
                  "Byla vaše tvář nezakrytá a neoříznutá",
                  "Pokud máte na dokladu brýle, doporučujeme si je nasadit",
                  "Zkuste se vyfotit s jednoduchým pozadím",
                  "Zkuste se vyhnout ostrým stínům"
                ]
            }],
            "step1": {
              "select1": "Zvolte typ dokladu",
              "select2": "Zvolte dva doklady",
              "id": "Občanský průkaz",
              "drivingLicence": "Řidičský průkaz",
              "passport": "Cestovní pas",
              "bothSides": "Obě strany dokladu",
              "frontSide": "Přední strana dokladu",
              "backSide": "Zadní strana dokladu",
              "pictureSide": "Stránka s fotografií"
            },
            "step2": {
              "picture": "Fotografie",
              "ofId": "Občanského průkazu",
              "ofDrivingLicence": "Řidičského průkazu",
              "ofPassport": "Cestovního pasu"
            },
            "step3": {
              "selfie": "Vaše fotografie (selfie)"
            },
            "step4": {
              "fraud": {
                "uploading": "Nahrávání dokladů pro kontrolu",
                "analysing": "Probíhá kontrola dokladů, tento proces může trvat i několik minut"
              },
              "uploading": "Nahrávání dokladů pro analýzu",
              "analysing": "Probíhá analýza dokladů, tento proces může trvat i několik minut"
            }
          },
          "payment": {
            "intro": "Pro dokončení ověření totožnosti je nutno provést mikroplatbu v částce 1Kč z účtu ověřující osoby na údaje uvedené níže. Nezapomeňte vyplnit variabilní symbol a zprávu / poznámku platby. Ověřujete-li za právnickou osobu, je třeba, aby platba byla provedena z firemního účtu.",
            "title": "Údaje k platbě:",
            "cu": "Číslo účtu",
            "vs": "Variabilní symbol",
            "msg": "Zpráva / poznámka platby",
            "amount": "Částka",
            "qr": "K platbě můžete také využít QR kód",
            "finish": "Platba provedena"
          },
          "error": {
            "filesize": "Pozor! Fotografie nesmí překročit velikost",
            "validate": "Zkontrolujte zadané údaje.",
            "connection": "Chyba spojení",
            "analysing": "Nastala chyba při analýze dokladů",
            "upload": "Nastala chyba při nahrávání souboru",
            "qr": "Nastala chyba při sestavování QR kódu",
            "sms": "Nastala chyba při odesílání SMS"
          }
        }
      }
    },
    "en": {
      "ls": {
        "kyc": {
          "step": {
            "instructions": "Instructions",
            "type": "Document type",
            "upload": "Upload",
            "upload1": "First document",
            "upload2": "Second document",
            "selfie": "Selfie"
          },
          "intro": "Verification process consists of uploading pictures of identity documents and a photo of the face (selfie). We recommend that you perform this process on a mobile device.",
          "device": {
            "phone": "Send to smartphone",
            "or": "or",
            "desktop": "continue on this device"
          },
          "phone": {
            "intro": "You can verify your identity using camera on your mobile phone. You can view the verification page by scanning the QR code or you can send it via SMS.",
            "qr": "Scan the QR code:",
            "sms": "Send via SMS:",
            "link": "Or open the link in a browser on your device:",
            "limit": "The link is valid for 10 minutes.",
            "phoneNumber": "Phone number",
            "send": "Send",
            "loading": "Continue on your mobile phone..."

          },
          "process": {
            "continue": "Continue",
            "instructions": [{
                "title": "How to take picture of the document correctly:",
                "steps": [
                  "Place the document on a table top or other mat",
                  "Make sure there is enough light in the room",
                  "Try taking a picture from the above"
                ]
              }, {
                "title": "Photos should not have:",
                "steps": [
                  "Glares or shadows",
                  "Unreadable or obscured data",
                  "Be blurred"
                ]
              }, {
                "title": "When taking a selfie, make sure that:",
                "steps": [
                  "Your face is uncovered and uncropped",
                  "If you have glasses on the document, we recommend wearing them",
                  "Try taking a picture with a simple background",
                  "Try to avoid sharp shadows"
                ]
            }],
            "step1": {
              "select1": "Select Document Type",
              "select2": "Select two documents",
              "id": "ID card",
              "drivingLicence": "Driving License",
              "passport": "Passport",
              "bothSides": "Both Sides of the Document",
              "frontSide": "The front side of the Document",
              "backSide": "The back side of the document",
              "pictureSide": "Page with the Picture"
            },
            "step2": {
              "picture": "Picture of",
              "ofId": "ID card",
              "ofDrivingLicence": "Driving License",
              "ofPassport": "Passport"
            },
            "step3": {
              "selfie": "Your picture (selfie)"
            },
            "step4": {
              "fraud": {
                "uploading": "Uploading Files for verification",
                "analysing": "Verifying documents, this process can take several minutes"
              },
              "uploading": "Uploading Files for analysis",
              "analysing": "Analyzing documents, this process can take several minutes"
            }
          },
          "payment": {
            "intro": "To complete the verification of identity, it is necessary to make a micropayment in the amount of 1 CZK from the bank account of the verifying person to account listed below. Don't forget to fill in the variable symbol and the payment message / note.",
            "title": "Payment details",
            "cu": "Account number",
            "vs": "Variable symbol",
            "msg": "Payment message / note",
            "amount": "Amount",
            "qr": "You can also use the QR code to pay",
            "finish": "Payment is done"
          },
          "error": {
            "filesize": "Warning! Max allowed picture size is",
            "validate": "Check the entered data.",
            "connection": "Connection error",
            "analysing": "An error occurred while analysing documents",
            "upload": "An error occurred while uploading files",
            "qr": "QR code creation failed",
            "sms": "An error occurred while sending the SMS"
          }
        }
      }
    }
  }
</i18n>
